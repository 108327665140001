import { getBlankForm, getNewQuestionnaire, postBlankForm } from '@/entities/application/api/step2';
import { IBase } from '@/entities/application/model/types/step2.types';
import { sortQuestionnaire } from '@ifr/form-questionnaire/src/plugins/formUtilites';
import { Module } from 'vuex';
import type ExtendedAxiosError from '@/entities/application/model/types/shared/ExtendAxiosError';

interface IStep2Store {
  json: IBase[];
}
export default <Module<IStep2Store, any>>{
  state: {
    json: [],
  },
  mutations: {
    _setCurrentStore(state, quest: IBase[] = []) {
      state.json = quest;
    },
    setQuestionnaire(state, quest: IBase[] = []) {
      state.json = sortQuestionnaire(quest);
    },
  },
  actions: {
    async saveStore({ state, rootGetters, dispatch }, id = rootGetters.appId) {
      try {
        await postBlankForm(id, state.json);
      } catch (e: any) {
        const error = e as ExtendedAxiosError<null>;
        error.uri = rootGetters.uri.blankForm(id);
        dispatch('setError', error, { root: true });
        throw error;
      }
    },
    async getAppStore({ commit, rootGetters, dispatch }, id = rootGetters.appId): Promise<void> {
      try {
        const res = await getBlankForm(id);
        commit('_setCurrentStore', res.data);
      } catch (e: any) {
        const error = e as ExtendedAxiosError<null>;
        error.uri = rootGetters.uri.blankForm(id);
        dispatch('setError', error, { root: true });
        throw error;
      }
    },
    async getQuestionnaire({ commit, rootGetters }, id = rootGetters.appId) {
      let idN = '';
      if (window.localStorage.getItem('survey')) {
        idN = 'survey=' + window.localStorage.getItem('survey');
      } else {
        idN = 'survey=';
      }
      const response = await getNewQuestionnaire(id, idN);

      const { data } = response;
      if (data) {
        commit('setQuestionnaire', data);
      }
      return response;
    },
  },
  getters: {
    isJson: (state): boolean => !!state.json.length,
    json: (state): IBase[] => state.json,
  },
};
