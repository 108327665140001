import { axios } from '@/plugins/http-axios';
import { IBase } from '../model/types/step2.types';
import type { IBankOffices } from '../model/types/step2.types';
const applicationAlias = 'application';

export async function fetchBankOffices() {
  return await axios.get<IBankOffices[]>('bankoffices');
}

export async function postBlankForm(id: number, body: IBase[]) {
  return await axios.post<null>(`${applicationAlias}/blank-form/${id}`, body);
}

export async function getBlankForm(id: number) {
  return await axios.get<IBase[]>(`${applicationAlias}/blank-form/${id}`);
}

export async function getNewQuestionnaire(id: string, idN: string) {
  return await axios.get<IBase[]>(`${applicationAlias}/${id}/questionnaire-new?${idN}`);
}
