export default async function environment({ context, nextMiddleware }){
  try {
    await context.store.dispatch('getEnv')

    await Promise.all([
      context.store.dispatch('getI18n'),
      context.store.dispatch('getColor'),
    ])
  }
  catch(e){
    console.error(e)
    context.next(false)
  }

  context.store.dispatch('setTheme')

  return nextMiddleware()
}
