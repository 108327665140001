import { axios } from '../plugins/http-axios';
import { Module } from 'vuex';
interface IDataEnv {
  [key: string]: string;
}
interface IEnvState {
  env: IDataEnv;
}
export default <Module<IEnvState, any>>{
  state: {
    env: {},
  },
  mutations: {
    setEnv(state, data: IDataEnv) {
      state.env = data;
    },
  },
  actions: {
    getEnv({ commit, dispatch, getters, rootGetters }): Promise<void> {
      if (getters.isEnv) return Promise.resolve();
      return axios
        .get<IDataEnv>(rootGetters.uri.env)
        .then((resp) => {
          commit('setEnv', resp.data);
        })
        .catch((e) => {
          e.uri = rootGetters.uri.env;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
  },
  getters: {
    isEnv: (state): boolean => !!Object.keys(state.env).length,
    env: (state): IDataEnv => state.env,
    flag:
      (state) =>
      (code: string): boolean|undefined => {
        const flag = Object.keys(state.env).find((key) => key === code);

        //@ts-ignore
        return flag ? state.env[code] : undefined;
      },
    isDarkTheme: (state, getters): boolean => getters.flag('DARK_THEME_ACTIVATE'),
  },
};
