import Vue from 'vue'
import Vuex from 'vuex'
import {axios} from '@/plugins/http-axios'
import {uri} from './uri'
import step2 from './step2'
import step3 from './step3'
import user from './user'
import color from "./color"
import socket from "./socket"
import success from "@/store/success"
import env from './env'
import i18n from "./i18n"
import decision from "./decision"
import loader from "./loader"
import error from "./error"
import apps from "./apps"
import meta from "./meta"
import step from "./step"
import app from './app'
import system from "./system"
import certificates from "@/store/certificates"
import signatory from "@/store/signatory";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        uri,
        nextAppStep:0,
    },
    mutations: {
        emitGoToStep(state, step=0){
            state.nextAppStep = step
        },
    },
    actions: {
        logout({commit, dispatch}) {
            commit('setAuthorized')
            dispatch('closeSocket')
            commit('setApps')
            commit('step3/setId')
            localStorage.clear()

            return axios.get(uri.logout)
                .then(() => {
                    return true
                })
                .catch(e => {
                    e.uri = uri.logout
                    dispatch('setError', e)
                    return Promise.reject(e)
                })
        },
    },
    getters: {
        uri(state) {
            return state.uri
        },
    },
    modules: {
        //Общие, базовые
        system,
        user,
        env,
        i18n,
        color,
        loader,
        error,
        //Журнал запросов
        apps,
        socket,
        //Заявка
        app,
        // status,
        meta,
        step,
        decision,
        success,
        step2,
        step3,
        certificates,
        signatory,
        // marketing,
    },
})

if (process.env.NODE_ENV !== 'production'){
    store.subscribeAction((action, state) => {
        console.warn('Action:', action.type, action.payload)
    })
    store.subscribe((mutation, state) => {
        console.warn('Mutation:', mutation.type, mutation.payload)
    })
}

export default store
