import qs from 'qs'
import environment from './environment'

export default async function auth({ context, nextMiddleware }){
  if (context.store.getters.authorized === undefined){
    try {
      await context.store.dispatch('getUser');
      await environment({context, nextMiddleware: ()=>{} });
    }
    catch(e){
      console.error(e)
      context.next(false)
    }

    context.store.dispatch('setTheme')
  }

  if (context.store.getters.authorized === 'authorized'){
    return nextMiddleware()
  }
  else if (context.store.getters.authorized === 'no_authorized'){
    if (context.to.name === 'login')
      return context.next()
    else {
      const nextUrl = context.to.fullPath
      const query = qs.parse(nextUrl,{ delimiter: '?' } )
      const {phone} = query
      return context.next({name: 'login', params: {nextUrl: nextUrl, login:phone}, replace: true})
    }
  }
  return context.next(false)
}
