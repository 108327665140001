interface IService{
    key: string;
    name: string;
    id: string;
    enabled: boolean;
    config: string;
}

import {axios} from '@/plugins/http-axios';
function _createTagScript(w:Window, d:Document, s:string, l:string, i:string){
    //@ts-ignore
    w[l]=w[l]||[]; w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    let f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    //@ts-ignore
    j.async=true; j.src = 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
    //@ts-ignore
    f.parentNode.insertBefore(j,f);
    f=d.getElementsByTagName('noscript')[0];
    j=d.createElement('iframe');
    //@ts-ignore
    j.src = 'https://www.googletagmanager.com/ns.html?id='+i; j.height="0"; j.width="0";
    j.style.display = 'none';
    j.style.visibility = 'hidden';
    f.appendChild(j);
}

export function getMarketingGTM(){
    const url = 'marketing';
    const key = 'GTM';
    axios.get<IService>(url, {params:{key:key}})
        .then(resp=>{
            if (resp.data?.id && resp.data?.enabled) {
                _createTagScript(window, document,'script','dataLayer', resp.data.id);
            }
        })
      .catch(e=>{
        console.log('error', url, key);
        console.log(e);
      });
}
// Функция для создания скрипта Яндекс Метрики
function _createYandexScript(w: Window, d: Document, id: string, config: string) {
  // Создаём глобальную функцию ym, если её ещё нет
  (w as any).ym = (w as any).ym || function () {
    ((w as any).ym.a = (w as any).ym.a || []).push(arguments);
  };
  (w as any).ym.l = +new Date();

  // Создаём и подключаем скрипт Яндекс Метрики
  const s = d.createElement("script");
  s.async = true;
  s.src = "https://mc.yandex.ru/metrika/tag.js";
  s.onload = () => {
    try {
      // Инициализируем счетчик с переданным id и конфигурацией (если есть)
      (w as any).ym(id, "init", config ? JSON.parse(config) : {});
      console.log("Яндекс.Метрика инициализирована с id:", id);
    } catch (error) {
      console.error("Ошибка инициализации Яндекс.Метрики:", error);
    }
  };
  d.head.appendChild(s);

  // Добавляем noscript fallback для поддержки без JavaScript
  const noscript = d.createElement("noscript");
  noscript.innerHTML = `<div><img src="https://mc.yandex.ru/watch/${id}" style="position:absolute; left:-9999px;" alt="" /></div>`;
  d.body.appendChild(noscript);
}

export function getMarketingYandex() {
  const url = 'marketing';
  const key = 'yandex';//тот ли ключ?
  axios.get<IService>(url, { params: { key } })
    .then(resp => {
      if (resp.data?.id && resp.data?.enabled) {
        _createYandexScript(window, document, resp.data.id, resp.data.config);
      }
    })
    .catch(e => {
      console.log('error', url, key);
      console.log(e);
    });
}
