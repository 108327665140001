export default async function getApp({ context, nextMiddleware }){
	try {
		await context.store.dispatch('getApp', context.to.params.id)
		await context.store.dispatch('getMeta', context.to.params.id)
		await context.store.dispatch('getDecision', context.to.params.id)
	}
	catch(e){
		//Заявка не найдена
		if (e.response?.status === 400)
			return context.next({name:'requestJournal'})
		// console.error(e);
		context.next(false)
	}
	return nextMiddleware()
}
