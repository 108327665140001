<template>
  <v-alert
    id="alert"
    color="#C51162"
    dark
    icon="mdi-alert-circle"
    border="left"
    prominent
    :value="!!error.message"
    transition="fade-transition"
    dismissible
    dense
    @input="hide"
    class="zind"
  >
    <v-expansion-panels class="min-h">
      <v-expansion-panel>
        <v-expansion-panel-header>{{ error.name }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ error.message }}
          <!-- <div>Номер: {{error.key}}</div>
          <div v-if="isDebug">
            <div>{{error.message}}</div>
            <div v-if="error.fn">fn: {{error.fn}}</div>
            <div v-if="error.uri">uri: {{error.uri}}</div>
            <div v-if="Object.keys(error.params).length">params: {{error.params}}</div>
          </div>
          <div v-else>
            Обратитесь в службу поддержки банка
          </div> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
  name: 'Error',
  computed: {
    ...mapGetters({
      error: 'error',
    }),
  },
  methods: {
    hide() {
      this.$store.commit('clearError');
    },
  },
});
</script>
<style lang="sass">
.zind
  z-index: 999!important
.min-h
  min-width: 290px!important
#alert
  position: fixed
  min-width: 300px
  bottom: 20px
  right: 10px
.theme--dark.v-expansion-panels .v-expansion-panel
  background-color: rgba(0, 0, 0, 0) !important
</style>
