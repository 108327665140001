import {axios} from "../plugins/http-axios";
import {Module} from "vuex";

interface IAccount {
    accountnumber: string;
    receiver: string;
    innBank: string;
    kppBank: string;
    okpoBank: string;
    ogrnBank: string;
    correspondingAccount: string;
    bank: string;
    bikBank: string;
    reserveddate: string;
    openingdate: string;
};

class Account implements IAccount {
    accountnumber: string;
    receiver: string;
    innBank: string;
    kppBank: string;
    okpoBank: string;
    ogrnBank: string;
    correspondingAccount: string;
    bank: string;
    bikBank: string;
    reserveddate: string;
    openingdate: string;
    constructor({
            accountnumber = "",
            receiver = "",
            innBank = "",
            kppBank = "",
            okpoBank = "",
            ogrnBank = "",
            correspondingAccount ="",
            bank = "",
            bikBank ="",
            reserveddate = "",
            openingdate = "",

                }={}) {
        this.accountnumber = accountnumber;
        this.receiver = receiver;
        this.innBank = innBank;
        this.kppBank = kppBank;
        this.okpoBank = okpoBank;
        this.ogrnBank = ogrnBank;
        this.correspondingAccount = correspondingAccount;
        this.bank = bank;
        this.bikBank = bikBank;
        this.reserveddate = reserveddate;
        this.openingdate = openingdate;

    }
};
interface IDecisionState {
    account?: IAccount,
    decision?: null|string,
    message?: null|string
};

export default <Module<IDecisionState, any>>{
    state: {
        account: new Account(),
        decision: null,
        message: null
    },
    mutations:{
        setDecision(state, {account, decision, message}:IDecisionState=<IDecisionState>{}) {
            state.account = new Account(account);
            state.decision = decision;
            state.message = message;
        },
    },
    actions:{
        getDecision({getters, commit, dispatch, rootGetters}, id = getters.appId) {
            return axios.get<IDecisionState>(rootGetters.uri.decision(id))
                .then(resp => {
                    commit('setDecision', resp.data);
                    return Promise.resolve();
                })
                .catch(e => {
                    e.uri = rootGetters.uri.decision(id);
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
    },
    getters:{
        decision: state => state,
    },
};
