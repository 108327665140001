import {axios} from "../plugins/http-axios";
import {IData} from "@/types/globalTypes";
import {Module} from "vuex";

interface IError {
    type: string;
    key: string;
    message: string;
    stack: string;
    browser: string;
    uri: string;
    params: IData;
    response?: IData;
};

interface IErrorState {
    name: string;
    message: string;
    fn: string;
    uri: string;
    params: IData;
    key: string;
};

export default <Module<IErrorState, any>>{
    state:{
        name: '',
        message: '',
        fn:'',
        uri:'',
        params:{},
        key:'',
    },
    mutations:{
        setError(state, {message='', stack='', key='', uri='', params={}, response=undefined}:IError=<IError>{}) {
            if (response) {
                switch(response.status) {
                    case 500:
                        state.name = 'Внутренняя ошибка сервера';
                        break;
                    case 403:
                        return;
                    default:
                        state.name = 'Ошибка';
                }
                if (response.data){
                    if (response.data.message)
                        state.message = response.data.message;
                    else if (response.data.errorMessage)
                        state.message = response.data.errorMessage;
                    else if (typeof response.data == 'string')
                        state.message = response.data;
                    else
                        state.message = message;
                }
                else
                    state.message = message;
            }
            else {
                state.message = message;
                state.name = 'Ошибка клиента';
            }

            const fn = stack.match(/^\w+/);
            if(fn && fn.length)
                state.fn = fn[0];

            state.key = key;
            state.uri = uri;
            state.params = params;
        },
        clearError(state) {
            state.name = '';
            state.message = '';
        },
    },
    actions:{
        setError({commit, rootGetters}, e){
            if (e?.response?.status == 403 || e?.response?.status == 429)
                return;
            e.key = +new Date;
            const data = <IError>{
                type: 'lkk',
                key: e.key,
                message: e.name + ' ' + e.message,
                stack: e.stack,
                browser: navigator.userAgent,
                uri: '',
                params: {},
                response: e?.response,
            };

            if(e.uri)
                data.uri = e.uri;
            if(e.params)
                data.params = e.params;

            axios.post(rootGetters.uri.error, {data})
              .catch(e=>{});
            commit('setError', e);
        },
    },
    getters:{
        error: (state):IErrorState => state,
    },
};
