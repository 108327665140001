import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import ru from 'vuetify/src/locale/ru'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
/*
    theme: {
        themes: {
            light: {
                /!*primary: colors.orange.darken1, // #E53935
                secondary: colors.red.lighten4, // #FFCDD2
                accent: colors.indigo.base, // #3F51B5

                background: "#eeeeee", //todo цвет приходит с бекенда
                headerColor: "#ddd",// todo цвет приходит с бекенда
                navbarColor: "#333",// todo цвет приходит с бекенда
                footerColor: "#333",// todo цвет приходит с бекенда

                checkColor: colors.orange.darken1,// todo цвет приходит с бекенда
                radioColor: colors.orange.darken1,// todo цвет приходит с бекенда
                selectColor: colors.orange.darken1,// todo цвет приходит с бекенда
                inputColor: colors.orange.darken1,// todo цвет приходит с бекенда*!/

            },
            dark:{
                //todo сделать и на backend, если нет! Задать палитру!
            }

        },

    },
*/
    lang: {
        locales: { ru },
        current: 'ru',
    },
})
