import { axios } from '../plugins/http-axios';
import { Module } from 'vuex';
import { IStatus } from '@/store/app';

export interface IOptions {
  page: number;
  pageCount: number;
  itemsPerPage: number;
  totalCount: number;
  sortBy: string[];
  sortDesc: boolean[];
  search: string;
}
function _getXOptions(headers:any ={}): IOptions {
  return {
    page: +headers['x-pagination-current-page'] || 0,
    pageCount: +headers['x-pagination-page-count'] || 0,
    itemsPerPage: +headers['x-pagination-per-page'] || 0,
    totalCount: +headers['x-pagination-total-count'] || 0,
    sortBy: [],
    sortDesc: [],
    search: '',
  };
}

interface IApp {
  id: string;
  orgnameshort: string;
  inn: string;
  createdate: string;
  number: string;
  status: IStatus;
  creator?: string | any;
  activeClient?: string | any;
}

interface IAppsState {
  apps: IApp[];
  options: IOptions;
}

export default <Module<IAppsState, any>>{
  state: {
    apps: [],
    options: {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      totalCount: 0,
      sortBy: [],
      sortDesc: [],
      search: '',
    },
  },
  mutations: {
    setOptions(state, options = {}) {
      for (let key in state.options) {
        //@ts-ignore
        state.options[key] = options[key];
      }
      localStorage.setItem('requestJournal', JSON.stringify(options));
    },
    setStatusById(state, { id = '', status = {} } = {}) {
      const app = state.apps.find((app) => app.id == id);
      if (app) app.status = status;
    },
    setApps(state, data = []) {
      state.apps = data;
    },
    /*
        //Расписать структуру входящего data, сделать так же в app.js->setApp
        pushApps(state, data = {}) {
            state.apps.unshift(data)
        },
*/
  },
  actions: {
    async requestUploadBinaryFiles({ commit, dispatch }, formData) {
      const headers = { 'Content-Type': 'multipart/form-data' };
      try {
        const response = await axios.post<string>('/signature', formData, { headers });
        return response;
      } catch (error) {
        dispatch('setError', error);
        return error;
      }
    },
    getApplications({ commit, dispatch, getters, rootGetters }, options: IOptions) {
      // if (getters.isApps)
      //     return Promise.resolve(getters.apps);

      const { sortBy, sortDesc, page, itemsPerPage: perPage, search = '' }: IOptions = options;
      const params = {
        params: {
          page: page || undefined,
          perPage: perPage || undefined,
          sort: sortBy[0],
          direct: sortDesc[0] ? 'desc' : sortDesc[0] === false ? 'asc' : undefined,
          search: search || undefined,
        },
      };
      setTimeout(() => {
        return axios
          .get<IApp[]>(rootGetters.uri.apps, params)
          .then((resp) => {
            commit('setApps', resp.data);
            const options: IOptions = _getXOptions(resp.headers);
            options.sortBy = sortBy;
            options.sortDesc = sortDesc;
            options.search = search;

            commit('setOptions', options);
            return Promise.resolve();
          })
          .catch((e) => {
            e.uri = rootGetters.uri.apps;
            dispatch('setError', e);
            return Promise.reject(e);
          });
      }, 200);
    },
  },
  getters: {
    isApps: (state: any): number => state.apps.length,
    apps: (state): IApp[] => state.apps,
    options: (state): IOptions => state.options,
  },
};
