import {axios} from "../plugins/http-axios";
import {Module} from "vuex";

export interface IDataI18n {
    [key:string]:string
};

interface II18nState{
    i18n: IDataI18n;
};
export default <Module<II18nState, any>>{
    state:{
        i18n: {},
    },
    mutations:{
        setI18n(state, data) {
            state.i18n = data;
        },
    },
    actions:{
        getI18n({state, commit, dispatch, rootGetters}) {
            if (Object.keys(state.i18n).length)
                return true;
            return axios.get(rootGetters.uri.i18n)
                .then(resp => {
                    commit('setI18n', resp.data);
                    return true;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.i18n;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
    },
    getters:{
        voc: state => state.i18n,
    },
};
