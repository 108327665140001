import colors from 'vuetify/lib/util/colors';
import { axios } from '../plugins/http-axios';
import Vue from 'vue';
import { Module } from 'vuex';

interface IDataColor {
  [key: string]: string;
}

interface IColorState {
  color: IDataColor;
  applied: boolean;
}

export default <Module<IColorState, any>>{
  state: {
    color: {},
    applied: false,
  },
  mutations: {
    setColor(state, data: IDataColor) {
      state.color = data;
    },
    setColorApplied(state, status = false) {
      state.applied = status;
    },
  },
  actions: {
    async getColor({ commit, dispatch, rootGetters }): Promise<void> {
      return axios
        .get<IDataColor>(rootGetters.uri.color)
        .then((resp) => {
          commit('setColor', resp.data);
          return Promise.resolve();
        })
        .catch((e) => {
          e.uri = rootGetters.uri.color;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    setTheme({ state, commit, getters }) {
      if (!state.color.applied) {
        Vue.prototype.$vuetify.framework.theme.themes.light = {
          primary: getters.color.PRIMARY_LIGHT, // #E53935
          secondary: colors.red.lighten4, // #FFCDD2
          accent: colors.indigo.base, // #3F51B5
          error: '#ff0d00',
          confirmBtnTextColor:  getters.color.CONFIRM_BUTTON_TEXT_COLOR_LIGHT,
          headerTextColor: getters.color.HEADER_TEXT_COLOR_LIGHT,
          headerBgColor: getters.color.HEADER_BG_COLOR_LIGHT,
          popupBackgroundColor: getters.color.POPUP_BACKGROUND_COLOR_LIGHT,
          lkkRequestBtn: getters.color.LKK_REQUEST_CREATION_COLOR_LIGHT,
          iconSuccessfullyColor: getters.color.ICON_SUCCESSFULLY_COLOR_LIGHT,
          iconWarningColor: getters.color.ICON_WARNING_COLOR_LIGHT,
          iconNegativeColor: getters.color.ICON_NEGATIVE_COLOR_LIGHT,

          blockContacts: getters.color.BLOCK_CONTACTS_LIGHT,
          blockContactsText: getters.color.BLOCK_CONTACTS_TEXT_LIGHT,
          sidebarBlockColorBtn: getters.color.SIDEBAR_BLOCK_COLOR_BTN_LIGHT,
          btnStepColor: getters.color.BTN_COLOR_TEXT_LIGHT,
          tabs: getters.color.TABS_COLOR_LIGHT,

          modalColorText: getters.color.WINDOW_PROTECTOR_TEXT_COLOR_LIGHT,
          noColorText: getters.color.WINDOW_PROTECTOR_TEXT_COLOR_NO_BUTTONS_LIGHT,
          background: getters.color.BG_COLOR_MAIN_LIGHT,
          headerColor: getters.color.BG_COLOR_TOP_PANEL_LIGHT,
          navbarColor: getters.color.BG_COLOR_LEFT_PANEL_LIGHT,
          navBarCardColor: getters.color.BG_COLOR_LEFT_PANEL_ACTIVE_LIGHT,
          footerColor: '#333',
          fieldSteperColor: getters.color.FIELD_BG_COLOR_LIGHT,
          SteperIconApplyColor: getters.color.STEPPER_ICON_APPLY_COLOR_LIGHT,
          SteperIconEditColor: getters.color.STEPPER_ICON_EDIT_COLOR_LIGHT,
          linesColor: getters.color.LINES_COLOR_LIGHT,
          otherColor: getters.color.OTHER_ELEMENT_COLOR_LIGHT,
          dialogColor: getters.color.DIALOG_COLOR_LIGHT,

          checkColor: getters.color.CHECK_COLOR_LIGHT,
          radioColor: getters.color.RADIO_COLOR_LIGHT,
          selectColor: getters.color.SELECT_COLOR_LIGHT,
          inputColor: getters.color.INPUT_COLOR_LIGHT,
          buttonColor: getters.color.BUTTON_COLOR_LIGHT,
          buttonTextColor: getters.color.BUTTON_TEXT_COLOR_LIGHT,
          linkColor: getters.color.LINK_COLOR_LIGHT,

          supportLogin: getters.color.LKK_SUPPORT_TITLE_LOGIN_COLOR_LIGHT,
          supportInside: getters.color.LKK_SUPPORT_TITLE_INSIDE_COLOR_LIGHT,
          btnRegularLkk: getters.color.LKK_COLOR_CONFIRM_BUTTON_LIGHT,
          loginPanelColor: getters.color.LKK_LOGIN_PANEL_COLOR_LIGHT,
        };
        Vue.prototype.$vuetify.framework.theme.themes.dark = {
          primary: getters.color.PRIMARY_DARK, // #E53935
          secondary: colors.red.lighten4, // #FFCDD2
          accent: colors.indigo.base, // #3F51B5
          error: '#ff0d00',
          confirmBtnTextColor:  getters.color.CONFIRM_BUTTON_TEXT_COLOR_DARK,
          headerTextColor: getters.color.HEADER_TEXT_COLOR_DARK,
          headerBgColor: getters.color.HEADER_BG_COLOR_DARK,
          popupBackgroundColor: getters.color.POPUP_BACKGROUND_COLOR_DARK,
          lkkRequestBtn: getters.color.LKB_REQUEST_CREATION_COLOR_DARK,
          iconSuccessfullyColor: getters.color.ICON_SUCCESSFULLY_COLOR_DARK,
          iconWarningColor: getters.color.ICON_WARNING_COLOR_DARK,
          iconNegativeColor: getters.color.ICON_NEGATIVE_COLOR_DARK,

          blockContacts: getters.color.BLOCK_CONTACTS_DARK,
          blockContactsText: getters.color.BLOCK_CONTACTS_TEXT_DARK,
          sidebarBlockColorBtn: getters.color.SIDEBAR_BLOCK_COLOR_BTN_DARK,
          btnStepColor: getters.color.BTN_COLOR_TEXT_DARK,
          tabs: getters.color.TABS_COLOR_DARK,

          modalColorText: getters.color.WINDOW_PROTECTOR_TEXT_COLOR_DARK,
          noColorText: getters.color.WINDOW_PROTECTOR_TEXT_COLOR_NO_BUTTONS_DARK,
          background: getters.color.BG_COLOR_MAIN_DARK,
          headerColor: getters.color.BG_COLOR_TOP_PANEL_DARK,
          navbarColor: getters.color.BG_COLOR_LEFT_PANEL_DARK,
          navBarCardColor: getters.color.BG_COLOR_LEFT_PANEL_ACTIVE_DARK,
          footerColor: '#333',
          fieldSteperColor: getters.color.FIELD_BG_COLOR_DARK,
          SteperIconApplyColor: getters.color.STEPPER_ICON_APPLY_COLOR_DARK,
          SteperIconEditColor: getters.color.STEPPER_ICON_EDIT_COLOR_DARK,
          linesColor: getters.color.LINES_COLOR_DARK,
          otherColor: getters.color.OTHER_ELEMENT_COLOR_DARK,
          dialogColor: getters.color.DIALOG_COLOR_DARK,
          btnRegularLkk: getters.color.LKK_COLOR_CONFIRM_BUTTON_DARK,
          checkColor: getters.color.CHECK_COLOR_DARK,
          radioColor: getters.color.RADIO_COLOR_DARK,
          selectColor: getters.color.SELECT_COLOR_DARK,
          inputColor: getters.color.INPUT_COLOR_DARK,
          buttonColor: getters.color.BUTTON_COLOR_DARK,
          buttonTextColor: getters.color.BUTTON_TEXT_COLOR_DARK,
          linkColor: getters.color.LINK_COLOR_DARK,

          supportLogin: getters.color.LKK_SUPPORT_TITLE_LOGIN_COLOR_DARK,
          supportInside: getters.color.LKK_SUPPORT_TITLE_INSIDE_COLOR_DARK,

          loginPanelColor: getters.color.LKK_LOGIN_PANEL_COLOR_DARK,
        };
        commit('setColorApplied', false);
      }
    },
    getDesignScheme({ dispatch, rootGetters }): Promise<void> {
      return axios
        .get<string>(rootGetters.uri.getSchema)
        .then((resp) => {
          if (/dark/i.test(resp.data)) Vue.prototype.$vuetify.framework.theme.dark = true;
        })
        .catch((e) => {
          e.uri = rootGetters.uri.getSchema;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
  },
  getters: {
    color: (state): IDataColor => state.color,
    typeColorSchema: (): 'dark' | 'light' => (Vue.prototype.$vuetify.framework.theme.dark ? 'dark' : 'light'),
    theme: (state, getters): IDataColor => Vue.prototype.$vuetify.framework.theme.themes[getters.typeColorSchema],
    logoLoginImgW: (state, getters): string => getters.color.LOGO_IMAGE_WIDTH,
    logoLoginImgH: (state, getters): string => getters.color.LOGO_IMAGE_HEIGHT,
    headerLogoImgW: (state, getters): string => getters.color.HEADER_IMG_WIDTH,
    headerLogoImgH: (state, getters): string => getters.color.HEADER_IMG_HEIGHT,
    animationsEnable: (state, getters): string => getters.color.ANIMATION_ENABLE,
    dialogWhiteTextEnable: (state, getters): boolean => getters.color.DIALOG_COLOR_TEXT_WHITE === 'true',
  },
};
