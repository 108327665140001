import {Module} from "vuex";

export interface ISystem {
    session?: boolean,
    dDos: boolean,
    textSeconds: number,
};
export class System implements ISystem{
    session?: boolean;
    dDos: boolean;
    textSeconds: number;

    constructor({session = true,
                dDos = false,
                textSeconds = 0,
                } = {}) {
        this.session = session;
        this.dDos = dDos;
        this.textSeconds = textSeconds;
    }
};

export default <Module<ISystem, any>>{
    state:{
        session: true,
        dDos: false,
        textSeconds: 0,
    },
    mutations:{
        setSession(state, data:boolean = false){
            state.session = data;
        },
        setDDos(state, data:ISystem = new System()){
            state.dDos = data.dDos;
            state.textSeconds = data.textSeconds;
        },
    },
    getters:{
        isCloseSession: (state):boolean => !state.session,
        isDDos: (state):boolean => state.dDos,
        getTextSeconds: (state):number => state.textSeconds,
    }
};
