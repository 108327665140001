<template>
  <Modal :value="isDDos" width="350" @input="close">
    <template v-slot:content>
      <p class="text-center font-weight-medium">Превышено количество обращений к сервису</p>
      <p class="text-center font-weight-bold">{{ getCount }}</p>
    </template>
    <template v-slot:action>
      <v-btn large color="buttonColor"> Закрыть </v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import { mapGetters /*, mapMutations*/ } from 'vuex';
import Modal from '@/components/Modal.vue';
import { defineComponent } from 'vue';
import store from '@/store';
import { ISystem } from '@/store/system';

export default defineComponent({
  name: 'DDos',
  components: { Modal },
  setup() {
    const setDDos = (data?: ISystem): void => {
      store.commit('setDDos', data);
    };
    return {
      setDDos,
    };
  },
  data() {
    return {
      seconds: 0,
      timerId: 0,
    };
  },
  computed: {
    ...mapGetters({
      isDDos: 'isDDos',
      getTextSeconds: 'getTextSeconds',
    }),
    getCount(): string {
      const timer = new Date(this.seconds);
      const min = timer.getMinutes();
      const sec = timer.getSeconds();
      return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
    },
  },
  methods: {
    // ...mapMutations(['setDDos']),
    close() {
      this.setDDos();
    },
    _countDown(count: number) {
      if (count) {
        count *= 1000;
        clearTimeout(this.timerId);
        this.seconds = count; //- 1000;
        const go = () => {
          if (this.seconds > 0) {
            this.seconds -= 1000;
            this.timerId = setTimeout(go, 1000);
          }
        };

        setTimeout(go, 1000);
      }
    },
  },
  updated() {
    this._countDown(this.getTextSeconds);
  },
});
</script>
