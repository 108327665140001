<template>
  <v-dialog
    :value="value"
    :width="width"
    scrollable
    :fullscreen="fullscreen"
    :persistent="closeOnWrap"
    @input="close"
  >
    <v-card>
      <div class="px-5 pt-5">
        <slot name="content" />
      </div>
      <v-card-actions class="pa-4">
        <div
          style="width: 100%"
          class="d-flex justify-center"
          @click="close"
        >
          <slot name="action">
            <v-btn
              width="120"
              color="primary"
              @click="close"
            >
              Закрыть
            </v-btn>
          </slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props:{
    modelValue:{
      type: Boolean,
      default: false,
    },
    value:{
      type: Boolean,
      default: false,
    },
    fullscreen:{
      type: Boolean,
      default: false,
    },
    width:{
      type: String,
      default: '400',
    },
    closeOnWrap:{
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'm-close'],
  methods: {
    close() {
      this.$emit('input', false)
      this.$emit('update:modelValue', false)
      this.$emit('m-close')
    },
  },
}
</script>
