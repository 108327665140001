import step2_store from '@/store/step2_store';
import { Module } from 'vuex';
import { IOfficesOfRegion, IBankOffice } from '@ifr/form-questionnaire/src/types/bankRegion';
import { fetchBankOffices } from '@/entities/application/api/step2';
import type ExtendedAxiosError from '@/entities/application/model/types/shared/ExtendAxiosError';
interface IBankOffices {
  id: number;
  inn: string;
  kpp: string;
  name: string;
  ogrn: string;
  okpo: string;
  regions: IBankRegion[];
}

interface IBankRegion {
  hide: boolean;
  id: number;
  name: string;
  offices: IBankOffice[];
  orderPosition: number;
}

interface IBankState {
  id: string;
  bankingRegions: IOfficesOfRegion[];
}

export interface IRegion {
  region: string;
  office: {
    name: string;
    shortaddress: string;
  };
}
class Region implements IRegion {
  region: string;
  office: {
    name: string;
    shortaddress: string;
  };
  constructor(
    {
      region = '',
      office = {
        name: '',
        shortaddress: '',
      },
    } = <IRegion>{},
  ) {
    this.region = region;
    this.office = office;
  }
}

export default <Module<IBankState, any>>{
  namespaced: true,
  state: {
    id: '',
    bankingRegions: [],
  },
  mutations: {
    setId(state, id: string = '') {
      state.id = id;
    },
    //todo вынести сортировку регионов и офисов на бэк
    _setBankingRegions(state, regions: IBankRegion[]) {
      regions.sort((a, b) => {
        return a.orderPosition - b.orderPosition;
      });

      state.bankingRegions = regions.map((item) => {
        item.offices.sort((a, b) => {
          return a.sortedItem - b.sortedItem;
        });
        return { text: item.name, value: item.offices };
      });
    },
  },
  actions: {
    async getBankingRegions({ commit, dispatch, rootGetters, getters }): Promise<any> {
      if (rootGetters.env.HIDE_OFFICE) return Promise.resolve();

      if (getters.isBankingRegions) return getters.bankingRegions;

      try {
        const res = await fetchBankOffices();
        commit('_setBankingRegions', res.data[0].regions);
        return Promise.resolve();
      } catch (e: any) {
        const error = e as ExtendedAxiosError<null>;
        error.uri = rootGetters.uri.bankOffices;
        dispatch('setError', error, { root: true });
        throw error;
      }
    },
  },
  getters: {
    isBankingRegions: (state): boolean => !!state.bankingRegions.length,
    bankingRegions: (state): IOfficesOfRegion[] => state.bankingRegions,
    oldId: (state): string => state.id,
    getRegion:
      (state, getters) =>
      (officeId: number): IRegion => {
        let office: IBankOffice | undefined;
        let region: string | undefined;
        const regions: IOfficesOfRegion[] = getters.bankingRegions || [];
        for (let i = 0; i < regions.length && !office; i++) {
          office = regions[i].value.find((office) => office.id === officeId);
          region = regions[i].text;
        }

        if (!office) {
          return new Region();
        }

        return new Region(<IRegion>{ region, office });
      },
  },
  modules: {
    store: step2_store,
  },
};
