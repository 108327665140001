export default function guest({ context, nextMiddleware }){
    if(context.store.getters.authorized === 'authorized'){
        console.log('guest Rules1, authorized')
        return context.next({
            name: 'requestJournal'
        })
    }

    console.log('guest Rules2', context.store.getters.authorized)
    return nextMiddleware()
}
