import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from './middleware/auth';
import guest from './middleware/guest';
import getApp from './middleware/getApp';
import goToApp from './middleware/goToApp';
import middlewarePipeline from './middlewarePipeline';
import store from '../store';
import unlockApp from "@/router/middleware/unlockApp";
import environment from "@/router/middleware/environment";

Vue.use(VueRouter);

const routes = [
  {
    path: '/signatory/:appId/:signatoryId',
    props: true,
    name: 'signature',
    component: () => import('../pages/signatory/index.vue'),
    meta: {
      middleware: [environment],
    },
  },
  {
    path: '/login/:login?',
    props: true,
    name: 'login',
    component: () => import('../components/Login/index.vue'),
    meta: {
      middleware: [auth, guest],
    },
  },
  {
    path: '/',
    name: 'home',
    redirect: 'request-journal',
    component: () => import('../components/Content.vue'),
    children: [
      {
        path: 'request-journal',
        name: 'requestJournal',
        component: () => import('../pages/request-journal/RequestJournal.vue'),
        meta: {
          middleware: [auth, unlockApp],
        },
      },
      {
        path: 'bank-materials',
        name: 'bankMaterials',
        component: () => import('../pages/bank-materials/BankMaterials.vue'),
        meta: {
          middleware: [auth, unlockApp],
        },
      },
      {
        path: 'application/:id',
        name: 'application',
        props: true,
        component: () => import('../pages/application/Application.vue'),
        meta: {
          middleware: [auth, getApp],
        },
        children: [
          {
            path: 'step1',
            name: 'step1',
            props: true,
            meta: {
              middleware: [goToApp],
            },
            component: () => import('../pages/application/step-pages/Step1.vue'),
          },
          {
            path: 'step2',
            name: 'step2',
            props: true,
            meta: {
              middleware: [goToApp],
            },
            component: () => import('../pages/application/step-pages/Step2.vue'),
          },
          {
            path: 'step3',
            name: 'step3',
            props: true,
            meta: {
              middleware: [goToApp],
            },
            component: () => import('../pages/application/step-pages/Step3.vue'),
          },
          {
            path: 'step4',
            name: 'step4',
            props: true,
            meta: {
              middleware: [goToApp],
            },
            component: () => import('../pages/application/step-pages/Step4.vue'),
          },
        ],
      },
      {
        path: 'example-application/:id',
        name: 'example-application',
        component: () => import('../pages/example-application/ExampleApplication.vue'),
        meta: {
          middleware: [auth, getApp],
        },
      },
      {
        path: 'example-sign/:id',
        name: 'ExampleSignApplication',
        component: () => import('../pages/example-application/ExampleSignApplication.vue'),
        meta: {
          middleware: [auth, getApp],
        },
      },
    ],
  },
  {
    path: '*',
    redirect: 'request-journal',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  if (!to.meta.middleware?.length) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
