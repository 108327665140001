import { axios } from '@/plugins/http-axios';
import { Module } from 'vuex';
import { IAddressValue } from '@ifr/form-questionnaire/src/types/Elements';
import QuestConfig, { IQuestConfig } from '@ifr/form-questionnaire/src/types/config';
import Url from '@ifr/form-questionnaire/src/types/url';

export interface IStatus {
  id: number;
  code: string;
  name: string;
  message: string;
  messageError: string;
  final: boolean;
}
class Status implements IStatus {
  id: number;
  code: string;
  name: string;
  message: string;
  messageError: string;
  final: boolean;
  constructor({ id = 0, code = '', name = '', message = '', messageError = '', final = false } = {}) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.message = message;
    this.messageError = messageError;
    this.final = final;
  }
}
interface IApp {
  id: string;
  createDate: string;
  number: string;
  regaddressObject: IAddressValue;
  /*
            "regaddressObject": {
                "text": string|null,
                "value": {}|null
            },
    */
  mapRegaddress: string | null;
  actualaddress: string | null;
  regdate: string | null;
  orgnameshort: string | null;
  orgnamefull: string | null;
  inn: string;
  kpp: string | null;
  ogrn: string | null;
  okpo: string | null;
  legalform: string | null;
  isIndividual: boolean;
  okvedname: string | null;
  office: number | null;
  ownershipform: string | null;
  clientStatus: string | null;
  isAuto: boolean;
  shortNumber: string
}
interface IResponseApp {
  data: IApp;
  status: IStatus;
  message: string | null;
}
interface IAppState {
  app: IApp;
  status: IStatus;
}
export default <Module<IAppState, any>>{
  state: {
    app: {
      id: '',
      createDate: '',
      number: '',
      regaddress: '',
      regaddressObject: {
        text: '',
        value: {},
      },
      mapRegaddress: null,
      actualaddress: '',
      regdate: '',
      orgnameshort: '',
      orgnamefull: '',
      inn: '',
      kpp: '',
      ogrn: '',
      okpo: '',
      legalform: null,
      isIndividual: false,
      okvedname: '',
      office: 0,
      ownershipform: '',
      clientStatus: '',
      isAuto: false,
      shortNumber: ''
    },
    status: new Status(),
  },
  mutations: {
    setOfficeId(state, officeId = 0) {
      state.app.office = officeId;
    },
    setAppId(state, id = '') {
      state.app.id = id;
      state.app.shortNumber = id
    },
    setAppFields(state, fields: any) {
      state.app.orgnameshort = fields.orgnameshort;
      state.app.regaddressObject = fields.regaddressObject;
      state.app.regdate = fields.regdate;
    },
    setApp(state, data: IApp = <IApp>{}) {
      for (let key in data) {
        //@ts-ignore
        state.app[key] = data[key];
      }
    },
    //Этот метод используется при работе с текущей заявкой. Нужен для работы со ссылкой на объект заявки из списка заявок. Для того тчобы в журнале заявок также менялся статус.
    changeAppStatus(state, data: IStatus) {
      const status = new Status(data);
      for (let key in status) {
        //@ts-ignore
        state.status[key] = status[key];
      }
    },
    //Метод вызывается при открытии заявки. Нужен для создания ссылки на обеъект apps[i] из списка заявок
    setAppStatusLink(state, data: IStatus) {
      state.status = data;
    },
  },
  actions: {
    async getApp({ getters, dispatch, rootGetters }, id: string): Promise<void> {
      if (!id) return Promise.reject();

      if (getters.isApp && getters.appId == id) return Promise.resolve();

      const url = rootGetters.uri.appById(id);
      return axios
        .get<IResponseApp>(url)
        .then((resp) => {
          return dispatch('setApp', resp.data);
        })
        .catch((e) => {
          //Заявка не найдена
          if (e.response?.status === 400) return Promise.reject(e);

          e.uri = url;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    // createRequest
    setApp({ commit, dispatch}, app: IResponseApp): Promise<void> {

      dispatch('unlockApp');
      commit('setApp', app.data);
      commit('setAppStatusLink', app.status);
      commit('setAppStep');

      localStorage.setItem('appId', app.data?.id || '');

      return Promise.resolve();
    },
    async annulApp({ state, commit, dispatch, rootGetters }, id = state.app.id): Promise<void> {
      return axios
        .post<IStatus>(rootGetters.uri.annul(id))
        .then((resp) => {
          commit('changeAppStatus', resp.data);
          return Promise.resolve();
        })
        .catch((e) => {
          e.uri = rootGetters.uri.annul(id);
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    async getAppStatus({ getters, commit, dispatch, rootGetters }, id = getters.appId): Promise<void> {
      return axios
        .get(rootGetters.uri.status(id))
        .then((resp) => {
          commit('changeAppStatus', resp.data);
          return Promise.resolve();
        })
        .catch((e) => {
          e.uri = rootGetters.uri.status(id);
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    async unlockApp({ dispatch, rootGetters }) {
      const appId = localStorage.getItem('appId');

      if (!appId)
        return Promise.resolve();

      const url = rootGetters.uri.resetClient(appId);
      return axios
        .post(url)
        .catch((e) => {
          e.uri = url;
          dispatch('setError', e);
        })
        .finally(() => {
          return Promise.resolve();
        })
    },
  },
  getters: {
    isApp: (state): boolean => !!state.app.id,
    app: (state): IApp => state.app,
    appId: (state): string => state.app.id,
    getAppShortNumber: ({ app }) => app.shortNumber,
    status: (state): IStatus => state.status,
    appStatusCode: (state): string => state.status.code,
    isIndividual: (state): boolean => state.app.isIndividual,
    isAuto: (state): boolean => state.app.isAuto,
    isAppStatusDenied: (state, getters): boolean => getters.isStatusDenied(getters.appStatusCode),
    isStatusDenied:
      (state) =>
        (code: string): boolean => {
          switch (code) {
            case 'CUST_CANCEL':
            case 'BANK_DECLINE':
            case 'BANK_ANNUL':
            case 'BANK_CANCEL':
              return true;
            default:
              return false;
          }
        },
    isAppStatusAllowed: (state, getters): boolean => getters.isStatusAllowed(getters.appStatusCode),
    isStatusAllowed:
      (state) =>
        (code: string): boolean =>
          code == 'ACC_OPENED',
    config: (state, getters, rootState, rootGetters): IQuestConfig => {
      return new QuestConfig({
        isIndividual: getters.isIndividual,
        isAutomaticApp: getters.isAuto,
        appRegAddress: state.app.regaddressObject,
        appInn: state.app.inn,
        appId: getters.appId,

        isHideOffice: rootGetters.env.HIDE_OFFICE,
        isHideInvitedManager: rootGetters.env.HIDE_INVITED_MANAGER,
        isHideButtonAddParticipants: rootGetters.env.HIDE_ADD_PARTICIPANT,
        isPassportRecognition: rootGetters.env.PASSPORT_RECOGNITION,
        minCountCounterparties: +rootGetters.env.MIN_NUMBER_COUNTERPARTIES,

        listRegions: rootGetters['step2/bankingRegions'],

        url: new Url({
          recognitionPassport: rootGetters.uri.recognitionPassport(getters.appId),
          addParticipant: rootGetters.uri.participantOfApp.bind(rootGetters.uri),
        }),

        voc: rootGetters.voc,
        clientPhone: rootGetters.userPhone,
        clientEmail: rootGetters.user.email,
        environment: 'LKK',
        baseUrl: axios.defaults.baseURL,
      });
    },
  },
};
