import { axios } from '@/plugins/http-axios';
import { Module } from 'vuex';
import { ISignatoryInfo, TableDocuments } from '@/features/application-review/ui/types';

interface ISignatoryStore {
  documents: TableDocuments[];
  signatoryFIO: string;
}
interface IPayloadGetDataSignatory {
  appId: string;
  signatoryId: string;
  isBankSignature: boolean;
  accessToken: string;
}

//todo перенести функционал обратно в компонент signatory
export default <Module<ISignatoryStore, any>>{
  state: {
    documents: [],
    signatoryFIO: '',
  },
  mutations: {
    // setAppStep(state, data) {
    // },
  },
  actions: {
    async getDataSignatory({ state, commit, dispatch, rootGetters }, payload: IPayloadGetDataSignatory): Promise<void> {
      const { appId = '', signatoryId = '', isBankSignature = false, accessToken } = payload;
      let url = '';
      if (isBankSignature) url = rootGetters.uri.signatoryBank(appId, signatoryId);
      else url = rootGetters.uri.signatory(appId, signatoryId);

      return axios
        .get<ISignatoryInfo>(url, {params:{accessToken}})
        .then((resp) => {
          if (resp.data) {
            const { questionnaire, documents, isIndividual, signatoryFIO } = resp.data;

            commit('setApp', { isIndividual });

            commit('step2/setQuestionnaire', questionnaire || []);
            state.documents = documents || [];
            state.signatoryFIO = signatoryFIO || '';
          }
          return Promise.resolve();
        })
        .catch((e) => {
          if (e.response.status === 403)
            return Promise.reject(e);

          e.uri = url;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
  },
  getters: {
    signedDocuments(state): TableDocuments[] {
      return state.documents;
    },
    listDocsECP(state): TableDocuments[] {
      return state.documents.filter((doc) => doc.isSigned === false);
    },
    isSign(state, getters): boolean {
      return !!getters.listDocsECP.length;
    },
  },
};
