import { getMarketingGTM, getMarketingYandex } from '@/plugins/marketing';
getMarketingGTM();
getMarketingYandex();
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from '@/App.vue';

import router from '@/router';
import store from '@/store';
import { axios } from '@/plugins/http-axios';
import { AxiosStatic } from 'axios';
import VueYandexMetrika from 'vue-yandex-metrika';

declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
  }
}

import { plugin } from '@ifr/form-questionnaire';
Vue.use(plugin);

import VueMoment from 'vue-moment';
import moment from 'moment';
import polyfills from '@/plugins/polyfills';

moment.locale('ru');

Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype.$vuetify = vuetify;

Vue.config.productionTip = false;

if (process.env.NODE_ENV == 'production') {
  Vue.config.errorHandler = function (e, vm, info) {
    e.name = info + ' ' + e.name;
    vm.$store.dispatch('setError', e);
  };
}

Vue.config.warnHandler = function (msg, vm, trace) {
  vm.$store.dispatch('setError', new Error(msg));
  console.error(msg + trace);
};
Vue.use(VueYandexMetrika, {
  id: 'default-id', // дефолтное значение, будет переинициализировано, если настройки придут из Vuex или API
  options: {
    webvisor: true,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
  },
  vueRouter: router
});
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    polyfills();
    this.$axios.interceptors.response.use(
      (resp) => {
        return resp;
      },
      (err) => {
        if (
          err?.response?.status == 403 &&
          this.$store.getters.authorized == 'authorized' &&
          !/user$/.test(err.request.responseURL)
        ) {
          if (this.$store.getters.loader.show) {
            this.$store.dispatch('setLoader');
          }

          this.$store.commit('setSession');
        } else if (err?.response?.status == 429) {
          this.$store.commit('setDDos', {
            dDos: true,
            textSeconds: err?.response.headers['x-rate-limit-retry-after-seconds'] || 0,
          });
        }

        return Promise.reject(err);
      },
    );
  },
}).$mount('#app');
