'use strict'
import Axios from 'axios'

const axios = Axios.create({
    baseURL: (process.env.VUE_APP_HOST ? process.env.VUE_APP_HOST : '') + process.env.VUE_APP_API,
    withCredentials: true,
    headers: {
        'X-Frame-Options': 'SAMEORIGIN',
        'Content-Security-Policy': `script-src "self" ${window.location.origin}`,
        'Permissions-Policy': `geolocation=(self ${window.location.origin})`,
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
    }
})

export { axios }


