import { axios } from '@/plugins/http-axios';
import { TUserInfo } from '../model/chat.types';

const hashUrl = '/admin/get-hash';
const credentialsUrl = '/admin/chat/get-credentials';

export async function getChatHash(body: TUserInfo) {
  return await axios.post(hashUrl, body);
}

export async function getChatCredentials() {
  return await axios.get(credentialsUrl);
}
