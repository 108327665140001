<template>
  <div
    v-show="loader.show"
    id="loader"
    class="zin-999"
  >
    <v-dialog
      :value="loader.show"
      hide-overlay
      persistent
      min-width="310"
      max-width="500"
      class="zin-999"
    >
      <v-card
        class="pt-4 pb-2"
        :style="{backgroundColor: theme.dialogColor}"
        :dark="dialogWhiteTextEnable"
      >
        <v-card-text class="text-truncate">
          {{ loader.message }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-4"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex"

export default {
  name: 'Loader',
  computed:{
    ...mapGetters([
      'dialogWhiteTextEnable',
      'loader',
      'theme'
    ]),
  }
}
</script>
<style lang="sass">
#loader
  width: 100vw
  height: 100vh
  z-index: 100
  position: fixed
  overflow: hidden
  background-color: rgba(0, 0, 0, 0.59)
.zin-999
  z-index: 999!important
</style>
