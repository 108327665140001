import SockJS from 'sockjs-client';
import {CompatClient, IFrame, Stomp} from '@stomp/stompjs/esm6';
// import {TextEncoder, TextDecoder} from 'text-encoding';
// if (!window['TextDecoder']) {
//     window['TextDecoder'] = TextDecoder;
// }
// if (!window['TextEncoder']) {
//     window['TextEncoder'] = TextEncoder;
// }
import {Module} from "vuex";
interface ISocketState{
    client: CompatClient|null,
    status: boolean;
    connect: boolean;
};
type TResolve = (client:CompatClient)=>void;
type TReject = (error:IFrame)=>void;
export default<Module<ISocketState, any>> {
    state:{
        // sock: null,
        client: null,
        status: false,
        connect: false,
    },
    mutations:{
        setSocket(state, {sock = null, client = null} = {}) {
            // state.socket.sock = sock;
            state.client = client;
        },
        setSocketStatus(state, status = false) {
            state.status = status;
        },
        setSocketConnect(state, status = false) {
            state.connect = status;
        },
    },
    actions:{
        createSocketClient({getters, commit, rootGetters}){
            return new Promise((resolve:TResolve, reject:TReject)=>{
                if (getters.isSocketClient)
                    resolve(getters.socketClient);
                else {
                    const url = rootGetters.uri.baseURL + rootGetters.uri.ws;
                    const client = Stomp.over(()=>new SockJS(url));
                    // let client = Stomp.client(uri.baseURL + uri.ws);
                    client.debug = function (str) {
                        // console.log(str);
                    };
                    client.reconnect_delay = 5000;
                    commit('setSocket', {client});
                    commit('setSocketStatus', true);

                    client.connect({}, (frame:IFrame) => {
                            commit('setSocketConnect', true);
                            resolve(client);
                        },
                        (error:IFrame) => {
                            console.log('socket connect error', error);
                            reject(error);
                        });
                }
            });
        },
        closeSocket({state, getters, commit}) {
            if (getters.isSocketClient && state.client) {
                state.client.disconnect();
                commit('setSocket');
                commit('setSocketStatus', false);
            }
        },
    },
    getters:{
        isSocketClient(state):boolean {
            return state.status;
        },
        socketClient(state):CompatClient|null {
            return state.client;
        },
    },
};
