import {axios} from "@/plugins/http-axios";
import {Module} from "vuex";

interface ICurrentStep {
    currentStep: number;
};
interface IResponseMetaStep{
    key: "passedStage";
    value: string;
};

export default <Module<ICurrentStep, any>>{
    state:{
        currentStep: 1,
    },
    mutations:{
        setAppStep(state, step = 1) {
            state.currentStep = step;
        },
    },
    actions:{
        setAppStep({state, getters, commit, dispatch, rootGetters}, step:number):Promise<void> {
            if (state.currentStep == step)
                return Promise.resolve();
            commit('setAppStep', step);
            const params = {key: "passedStage", value: step};
            //todo response возвращает {key: "passedStage"; value: string;}, а должен {key: "passedStage"; value: number;}
            return axios.post<IResponseMetaStep>(rootGetters.uri.meta(rootGetters.appId), params)
                .then(() => {
                    return Promise.resolve();
                })
                .catch(e => {
                    e.uri = rootGetters.uri.meta(rootGetters.appId);
                    e.params = params;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
    },
    getters:{
        currentStep: (state):number => state.currentStep,
    },
};
