import {axios} from "@/plugins/http-axios";
import {Module} from "vuex";

//Эти данные сервер сам выбирает из анкет на 1м и 2м шагах
interface IMeta {
    //Обязательные
    inviteManager: boolean,
    passedStage: string,
    //Не используются
    notifyByPhone?: boolean,
    notifyByEmail?: boolean,
    actualAddressMatch?: boolean,
};
interface IResponseMeta{
    key: string;
    value: any;
};


class Meta implements IMeta {
    inviteManager: boolean;
    passedStage: string;
    //Не используются
    // notifyByPhone: boolean;
    // notifyByEmail: boolean;
    // actualAddressMatch: boolean;
    constructor({
                    inviteManager = false,
                    passedStage = '',
                    // notifyByPhone = false,
                    // notifyByEmail = false,
                    // actualAddressMatch = false,
                }={}) {
        this.inviteManager = inviteManager;
        this.passedStage = passedStage;
        // this.notifyByPhone = notifyByPhone;
        // this.notifyByEmail = notifyByEmail;
        // this.actualAddressMatch = actualAddressMatch;
    }
};
interface IMetaState {
    meta: IMeta,
};

export default <Module<IMetaState, any>>{
    state:{
        meta: new Meta(),
    },
    mutations:{
        setMeta(state, data:IMeta) {
            state.meta = new Meta(data);
        },
    },
    actions:{
        getMeta({getters, commit, dispatch, rootGetters}, id:string = rootGetters.appId):Promise<void> {
            return axios.get<IResponseMeta[]>(rootGetters.uri.meta(id))
                .then(resp => {
                    if (resp.data instanceof Array && resp.data.length) {
                        const meta = <IMeta>resp.data.reduce((sum, item) => {
                            //@ts-ignore
                            sum[item.key] = item.value;
                            return sum;
                        }, {});

                        commit('setMeta', meta);
                        //todo response возвращает {key: "passedStage"; value: string;}, а должен {key: "passedStage"; value: number;}
                        if (meta.passedStage) {
                            commit('setAppStep', +meta.passedStage, {root:true});
                        }
                    }
                    return Promise.resolve();
                })
                .catch(e => {
                    e.uri = rootGetters.uri.meta(id);
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
    },
    getters:{
        meta: (state):IMeta => state.meta,
    },
};
