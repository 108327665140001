import { axios } from '@/plugins/http-axios';
import { Module } from 'vuex';

interface IUser {
  surname: string;
  name: string;
  agent: string;
  parname: string;
  phone: string;
  email: string;
}

class User implements IUser {
  surname: string;
  name: string;
  agent: string;
  parname: string;
  phone: string;
  email: string;
  constructor({ surname = '', name = '', agent = '', parname = '', phone = '', email = '' } = {}) {
    this.surname = surname;
    this.name = name;
    this.agent = agent;
    this.parname = parname;
    this.phone = phone;
    this.email = email;
  }
}
interface IUserState {
  user: IUser;
  authorized: string | undefined;
}

export default <Module<IUserState, any>>{
  state: {
    user: new User(),
    authorized: undefined,
  },
  mutations: {
    setAuthorized(state, value = undefined) {
      state.authorized = value;
    },
    setUser(state, data: IUser) {
      state.user = new User(data);
    },
  },
  actions: {
    getUser({ commit, dispatch, rootGetters }): Promise<string> {
      return axios
        .get<IUser>(rootGetters.uri.user)
        .then((resp) => {
          commit('setAuthorized', 'authorized');
          commit('setUser', resp.data);
          return Promise.resolve('authorized');
        })
        .catch((e) => {
          if ('response' in e && e.response instanceof Object && e.response.status == 403) {
            commit('setAuthorized', 'no_authorized');
            return Promise.resolve('no_authorized');
          }
          e.uri = rootGetters.uri.user;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
  },
  getters: {
    user: (state): IUser => state.user,
    authorized: (state): string | undefined => state.authorized,
    userPhone: (state): string => state.user.phone.replace(/(.+)(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1 ($2) $3 $4 $5'),
  },
};
