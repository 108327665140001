<template>
  <div v-if="isShow" class="chat_container">
    <a class="webim_button" href="#" rel="webim">
      <img :src="webimButton" border="0" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { getChatHash, getChatCredentials } from '../api';
import store from '@/store'
const webimDomain = ref('')
const webimAccountName = ref('')

const appShortNumber = computed(() => {
  return store.getters.getAppShortNumber
});

const isShow = computed(() => {
  return store.getters.authorized === 'authorized' && store.getters.env.WEBIM_CHAT_ENABLE
});

const webimButton = computed(() => {
  return `${webimDomain}/button.php`
})

const userGeetter = computed(() => {
  return store.getters.user
})

watch(appShortNumber, (newId, oldId) => {
  if(newId && newId !== oldId){
    chatInject(newId);
  }
});

watch(isShow, (newV, oldV) => {
  if(newV && newV !== oldV){
    chatCredentials();
  }
});

const chatCredentials = async () => {
  const response = await getChatCredentials()
  const { data } = response
  webimDomain.value = data.domain
  webimAccountName.value = data.accountName
  return response
}
const chatInject = async (appShortNumber: string) => {
  try {
    const userInfoChatData = {
      id: appShortNumber,
      display_name: userGeetter.value.name,
      phone: userGeetter.value.phone,
      email: userGeetter.value.email
    }
    const hash = (await getChatHash(userInfoChatData)).data;

    if (hash) {
      // @ts-ignore
      window.webim_visitor = {
        fields: userInfoChatData,
        hash,
      };

      // @ts-ignore
      if (window.webim) {
        // @ts-ignore
        window.webim.api.onProvidedVisitorChanged();

        return;
      }

      // @ts-ignore
      window.webim = {
        accountName: webimAccountName.value,
        domain: `${webimDomain.value}.webim.ru`,
        location: 'default',
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://${webimDomain.value}.webim.ru/js/button.js`;
      document.head.appendChild(script);
    }
  } catch (error) {
    console.error(error);
  }
}
</script>

<style lang="sass" scoped>
.chat_container
  height: 0
</style>
