import { axios } from '@/plugins/http-axios';
import { Module } from 'vuex';

export interface IDocument {
  allowTypes: string;
  description: string;
  digitalDocument: boolean;
  digitalDocumentNumber: string;
  extraId: string;
  fileCount: number;
  fioPassport: null | string;
  id: number;
  maxFileSize: number;
  name: string;
  orderNum: number;
  required: boolean;
  type: string;
  files: IFile[];
  requirements: IRequirement[];
  attachedFiles: File[];
  isAttachedFile: boolean;
  isRequirements: boolean;
  requirementId: string;
}

interface IDocumentState {
  id: string;
  documents: IDocument[];
}

interface IRequirement {
  artifactname: string;
  commentEmployee: string;
  completed: boolean;
  createDate: string;
  fileId: null | string;
  id: string;
  isAttachedFile: boolean;
}

export interface IResponseFile {
  id: string;
  name: string;
  createDate: string;
  validationDate: string | null;
  validated: null | boolean;
  origName: string;
  size: number;
  requirement: null | IRequirement;
  electroDocumentNumber: string | null;
  req: null | boolean;
}
export interface IFile extends IResponseFile {
  doctypeid: number;
  attachedFiles: File[];
  files: IFile[];
}
export interface IPayloadAddFileToReq {
  i: number;
  j: number;
  file: IFile;
}
export interface IPayloadAddFileToDoc {
  i: number;
  file: IFile;
}
export default <Module<IDocumentState, any>>{
  namespaced: true,
  state: {
    id: '',
    documents: [],
  },
  mutations: {
    setId(state, id: string = '') {
      state.id = id;
    },
    setDocs(state, docs: IDocument[]) {
      docs.forEach((doc) => {
        doc.allowTypes = '.' + doc.allowTypes.split(',').join(',.');
        doc.isRequirements = !!(doc.requirements.length && doc.files.length);
        doc.attachedFiles = [];

        if (doc.isRequirements) {
          //todo Исправить fileCount
          // doc.fileCountAbs = doc.fileCount + doc.requirements.length;
          doc.requirements = []; //Пересоздаём заново, чтобы появились ссылки
          doc.files.forEach((file) => {
            if (file.requirement && !file.requirement.completed) {
              file.requirement.isAttachedFile = false;
              file.attachedFiles = []; //корзина для прикреплённых файлов
              file.files = []; //корзина для сохранённых(загруженных) файлов
              doc.requirements.push(file.requirement); //Делаем ссылку
            }
          });
        } else if (doc.digitalDocument) {
          doc.isAttachedFile = !!doc.digitalDocumentNumber || !!doc.files.length;
        } else {
          doc.isAttachedFile = !!doc.files.length;
        }
      });

      state.documents = docs;
    },
    setAttachedDoc(state, { i, val = false }: { i: number; val: boolean }) {
      state.documents[i].isAttachedFile = val;
    },
    addFileToDoc(state, { i, file }: IPayloadAddFileToDoc) {
      state.documents[i].files.push(file);
      state.documents[i].isAttachedFile = true;
    },
    addFileToReq(state, { i, j, file }: IPayloadAddFileToReq) {
      state.documents[i].files[j].files.push(file);
      //@ts-ignore
      state.documents[i].files[j].requirement.isAttachedFile = true;
    },
  },
  actions: {
    async getDocuments({ commit, dispatch, rootGetters }, id = rootGetters.appId): Promise<IDocument[]> {
      if (id) {
        return axios
          .get<IDocument[]>(rootGetters.uri.documents(id))
          .then((resp) => {
            commit('setDocs', resp.data);
            return resp.data;
          })
          .catch((e) => {
            e.uri = rootGetters.uri.documents(id);
            dispatch('setError', e, { root: true });
            return Promise.reject(e);
          });
      } else {
        const e = new Error('Нет id');
        dispatch('setError', e, { root: true });
        return Promise.reject(e);
      }
    },
    async processingRequirements({ commit, dispatch, getters, rootGetters }): Promise<void> {
      if (rootGetters.appId != getters.oldId) {
        commit('setId', rootGetters.appId);
        await dispatch('getDocuments');
      }
      return Promise.resolve();
    },
  },
  getters: {
    docs: (state): IDocument[] => state.documents,
    oldId: (state): string => state.id,
  },
};
