<template>
  <Modal
    :value="isCloseSession"
    width="350"
  >
    <template #content>
      <p class="text-center font-weight-medium">
        Активный сеанс работы в Личном кабинете закончился. Для
        продолжения работы вам необходимо осуществить повторный вход в Личный кабинет
      </p>
    </template>
    <template #action>
      <v-btn
        large
        color="buttonColor"
        @click="logout"
      >
        Повторный вход
        <v-icon right>
          mdi-transit-connection-variant
        </v-icon>
      </v-btn>
    </template>
  </Modal>
</template>

<script>
import {mapGetters} from 'vuex'
import Modal from "@/components/Modal.vue"
export default {
  name: "ReLogin",
  components: {Modal},
  computed: {
    ...mapGetters(['isCloseSession']),
  },
  methods:{
    logout(){
      this.$store.dispatch('logout')
          .then(()=> {
            this.$store.commit('setSession', true)
            this.$router.push({name: 'login'})
          })
    },
  },
}
</script>
